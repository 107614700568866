import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Metcon Week`}</em></p>
    <p>{`100-Single Unders`}</p>
    <p>{`10-Bench Press (155/95)`}</p>
    <p>{`10-Ring Rows`}</p>
    <p>{`10-Hang Power Cleans (135/95)`}</p>
    <p>{`10-GHD Sitps`}</p>
    <p>{`90,9,9,9,9`}</p>
    <p>{`80,8,8,8,8`}</p>
    <p>{`70,7,7,7,7`}</p>
    <p>{`etc, t0 10,1,1,1,1`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      